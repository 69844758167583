<template>
    <div class="">
        <div class='break-before dark-text header-w-bg do-not-print-me'>Mental Status Assessment</div>
        <div class='flex do-not-print-me'>
            <span class='toggle-label right-10'>Set all categories to within normal limits</span>
            <Toggle
                id='normal-limits'
                class='toggle-switch'
                @input='normalize'
                :labelClass="'switch'"
                :spanClass="'slider'"
                :value='checkLimits'
            />
            <b> Yes </b>
        </div>

        <!-- section name   -->
        <div class="top-15 avoid-break" v-for='section in assessment' :key='section.name'>
            <div class=""><b class="dark-text">{{ section.name }}</b></div>
            <hr class="top-0 bottom-10" />
            <!--   category name  -->
            <div class='flex wrap colx3 assessment'>
                <div class="avoid-break" v-for='category in section.categories'
                :key='category.name'>
                    <div>{{ category.label }}</div>
                    <!--   checkbox items    -->
                    <div class='bordered checkboxes'>
                        <div class="" v-for='(option, key) in category.options' :key='option+key'>
                            <input class="color"
                                type='checkbox'
                                :value='option'
                                @input='(e) => handleOptionSelection(e, category.name, key)'
                                v-model='assessmentData[category.name]'
                            />
                            <label class='bottom-5'> {{ option }}</label>
                        </div>
                    </div>
                    <div v-if='category.req' style='text-align:right; color:red;'><b>Required</b></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Assessments',
        props: {
            // to render the "containers" of options
            assessment: {
                type: Array,
                required: true
                /** assessment: [{
                 *   name: "Section Name"
                 *   categories: [
                 *       {
                 *           name: 'category name'
                 *           options: [
                 *               Checkbox Item 1,
                 *               Checkbox Item2,
                 *               Checkbox Item 3,
                 *
                 *           ]
                 *       }
                 *  }]
                 * */
            },
            // data to store & display input options
            rec: {
                type: Object,
                required: true
            }
        },
        computed: {
            checkLimits() {

                // check if coming from bioPsycho Social (short)
                if (
                    this.rec.assessments?.behavior[1] == 'Attentive' &&
                    this.rec.assessments?.behavior[2] == 'Cooperative' &&
                    this.rec.assessments?.appearance[0] == 'Well Groomed' &&
                    this.rec.assessments?.appearance[1] == 'Appropriate Dress' &&
                    this.rec.assessments?.mood[1] == 'Pleasant' &&
                    this.rec.assessments?.mood[2] == 'Calm' &&
                    this.rec.assessments?.affect[1] == 'Appropriate' &&
                    this.rec.assessments?.speech[1] == 'Normal' &&
                    this.rec.assessments?.thought[1] == 'Normal'
                ) {
                    return true;
                }

                // check if coming from the progress note
                if (
                    this.rec.assessment?.behavior[1] == 'Attentive' &&
                    this.rec.assessment?.behavior[2] == 'Cooperative' &&
                    this.rec.assessment?.appearance[0] == 'Well Groomed' &&
                    this.rec.assessment?.appearance[1] == 'Appropriate Dress' &&
                    this.rec.assessment?.mood[1] == 'Pleasant' &&
                    this.rec.assessment?.mood[2] == 'Calm' &&
                    this.rec.assessment?.affect[1] == 'Appropriate' &&
                    this.rec.assessment?.speech[1] == 'Normal' &&
                    this.rec.assessment?.thought[1] == 'Normal'
                ) {
                    return true;
                }

                return false;
            },

            assessmentData() {

                //returns data for p-note
                if(this.rec.assessment){
                    return this.rec.assessment;
                }

                // returns data for bio psycho social
                return this.rec.assessments
            }
        },

        data() {
            return {
                normalized: false
            };
        },
        methods: {

            // handles when users "normalize" option inputs
            normalize(e) {
                let normalized = false;
                if (e == true) {
                    normalized = true;
                }
                this.$emit('normalized', normalized);
                return;
            },

            // handles user input on checkboxes
            handleOptionSelection(e, category, index) {
                let selection = {
                    value: e.target.checked ? e.target.value : '',
                    // category: this.handleString(category),
                    category: category,
                    index: index
                };

                this.$emit('optionSelected', selection);
            },

            handleString(string){
                let firstWord = string.split(" ")[0]
                return firstWord
            }
        }
    };
</script>