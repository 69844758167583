<template>
    <!-- TODO - Nate needs to add text-transform property to capitalize this. -->
    <div className="form-client" v-if="client" @click="gotoclient">
        <h1>Member Details</h1>
        <h2 class="margin-0">{{ client.last_name || 'None recorded' }}, {{ client.first_name || 'None recorded' }}</h2>
        <div class="pointer blue weight-600 hover-shadow inline-block" @click="gotoclient">ID# {{ client.id || 'None recorded' }}</div>

        <div>
            <div class="flex top-20">
                <div class="right-5"><b>Address:</b></div>
                <div>
                    <div>{{ client.street_address || 'None recorded' }} {{ client.city || 'None recorded' }}, {{ client.state || 'None recorded' }} {{ client.zip || 'None recorded' }}</div>
                </div>
            </div>
            <div class=""><b>Phone:</b> {{ client.phone || 'None recorded' }}</div>
            <div class=""><b>Email:</b> {{ client.email || 'None recorded' }}</div>
            <div class=""><b>Date of Birth:</b> {{ dob || 'None recorded' }}</div>
            <div class=""><b>Gender:</b> {{ client.gender || 'None recorded' }}</div>
            <div class=""><b>Identified Gender:</b> {{ client.identified_gender || 'None recorded' }}</div>
            <div class=""><b>Race:</b> {{ client.race || 'None recorded' }}</div>
            <div class=""><b>Marital Status:</b> {{ client.marital_status || 'None recorded' }}</div>
            <div v-if="!emergencyContacts">No Emergency Contact Listed</div>
            <div style="display: none;" else>
                <div class="top-20"><b>Emergency Contact Info:</b></div>
                <div v-for="emcontact in emergencyContacts" :key="emcontact.id">
                    <div>First Name: {{ emcontact.firstName }}</div>
                    <div>Last Name: {{ emcontact.lastName }}</div>
                    <div>Relation to Client: {{ emcontact.relationshipToClient }}</div>
                    <div>Phone: {{ emcontact.primaryPhone }}</div>
                    <div>Email: {{ emcontact.email }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { clients } from '@/util/apiRequests';
    import dayjs from 'dayjs';

    export default {
        name: 'FormClientFull',
        data() {
            return {
                loading: 1,
                client: {},
                emergencyContacts: []
            };
        },
        props: {
            client_id: {
                type: Number,
                default: 0
            }
        },
        computed: {
            // client() {
            //     return this.$store.getters['clientOverview/client'];
            // },
            dob() {
                return this.client && this.client.dob ? dayjs(this.client.dob.split('T')[0]).format('MM/DD/YYYY') : '';
            }
        },
        methods: {
            async getClient() {
                let response = await this.$api.get(clients.getClient(this.client_id));
                if (response && response.data && response.data.length) {
                    //Leave it like this, unless you intend to do this.client = response.data?.[0] and then emit the data back to parent. right now this is read only.
                    //    this.client.id = response.data?.[0]?.id;
                    //    this.client.firstName = response.data?.[0]?.first_name;
                    //    this.client.lastName = response.data?.[0]?.last_name;
                    this.client = response.data?.[0];
                }
                this.$emit('getClient', this.client);
            },
            async getEmergencyContacts() {
                let response = await this.$api.get(clients.getEmergencyDetails(this.client_id));
                if (response && response.data && response.data instanceof Array) {
                    this.emergencyContacts = response.data;
                }
            },
            async getInsurances() {
                let response = await this.$api.get(clients.getInsurances(this.client_id));
                if (response && response.data && response.data.length) {
                    this.client.insurances = response.data;
                }
                this.insurance = response.data;
            },

            async gotoclient() {
                this.$router.push({ name: 'ClientOverview', params: { record_id: this.client.id } });
            }
        },
        async created() {
            await this.getClient();
            await this.getEmergencyContacts();
            this.getInsurances();
        }
    };
</script>
