<template>
    <div>
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase() + '-page'">
            <ValidationObserver ref='form_bio'>
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id"><FormClient :client_id="client_id" @getClient="getClient" /><br /></div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany" />
                    </div>
                </div>

                <button
                    type="button"
                    @click="signature_ids = []"
                    v-if="signature_ids.length && original_signature_ids.length"
                    class="do-not-print-me"
                >
                    Edit & Create Addendum
                </button>
                <button
                    v-if="original_signature_ids.length && !signature_ids.length"
                    class="align-right"
                    type="button"
                    @click="signature_ids = original_signature_ids"
                >
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <!-- TAB INPUTS -->
                <input
                    id="page-tab-1"
                    class="page-tab-input do-not-print-me"
                    name="page-tab-input"
                    type="radio"
                    checked
                    @click="changeTab"
                />
                <input id="page-tab-2" class="page-tab-input do-not-print-me" name="page-tab-input" type="radio" @click="changeTab" />
                <input id="page-tab-3" class="page-tab-input do-not-print-me" name="page-tab-input" type="radio" @click="changeTab" />

                <!-- TABS CONTAINER -->
                <div class="company-settings-header page-tabs-container">
                    <h1>Biopsychosocial Assessment (Short)</h1>

                    <div>
                        {{ formattedDate }}
                    </div>
                    <hr class="top-5 bottom-20">
                    <div class="flex space-between do-not-print-me">
                        <div class="flex center wrap">
                            <template class="">
                                <label class="page-tab page-tab-1 do-not-print-me" for="page-tab-1">Section 1</label>
                                <label class="page-tab page-tab-2 do-not-print-me" for="page-tab-2">Section 2</label>
                                <label class="page-tab page-tab-3 do-not-print-me" for="page-tab-3">Section 3</label>
                            </template>
                        </div>
                        <div class="align-right do-not-print-me">
                            <button class="secondary nowrap" type="button" @click="printView()">Print View</button>
                        </div>
                    </div>
                </div>

                <!-- TABBED PAGE SECTIONS -->
                <section class="tabbed-page form_section_1 " id="page-tab-1"  v-disable-all="!pageMeta.permissions.edit">
                    <div class='dark-text header-w-bg avoid-break'>General Biopsychosocial Assessment Information</div>
                    <div class="flex space-between colx3 bottom-20">
                        <div>
                            <label for="assessor">Assessment Completed By</label>
                            <select v-model="assessor" id="assessor">
                                <option
                                    v-for="counselor in counselorOptions"
                                    :value="counselor.value"
                                    :key="counselor.value"
                                >{{ counselor.text }}</option
                                >
                            </select>
                        </div>
                        <div>
                            <label for="location">Location Completed at</label>
                            <select v-model="rec.location" id="location" :disabled="office_loc_disabled">
                                <option v-for="location in locationOptions" :value="location.id" :key="location.id">{{
                                        location.office_name
                                    }}</option>
                            </select>
                        </div>
                        <div>
                            <label>Referral Source</label>
                            <input type="text"  list="listing_dropdown" v-model="rec.referral_source" id="referral_source" label="Referral Source" />
                            <datalist id='listing_dropdown'>
                                <option v-for="referral in list_dropdown" :value="referral.name" :key="referral.id">{{
                                        referral.name
                                    }}</option>
                            </datalist>
                        </div>
                    </div>

                    <div class='dark-text header-w-bg avoid-break'>Presenting Problem and Symptoms</div>
                    <div>
                        <ExpandableTextArea
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="presenting_problem"
                            v-model="rec.presenting_problem"
                            label="Presenting Problem"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                    </div>

<!--                    <div class='dark-text header-w-bg avoid-break'>Mental Status</div>-->
<!--                    <div class="flex colx3 bottom bottom-10">-->
<!--                        <Dropdown-->
<!--                            class="flex-1 right-15"-->
<!--                            :options="opts.cognitive_function"-->
<!--                            v-model="rec.cognitive_function"-->
<!--                            label="Cognitive Function"-->
<!--                            id="cognitive_function"-->
<!--                            :required="true"-->
<!--                        />-->
<!--                        <Dropdown-->
<!--                            class="flex-1 right-15"-->
<!--                            :options="opts.orientation"-->
<!--                            v-model="rec.orientation"-->
<!--                            label="Orientation"-->
<!--                            id="orientation"-->
<!--                            :required="true"-->
<!--                        />-->
<!--                        <div></div>-->
<!--                    </div>-->

                    <!--  A  S  S  E  S  S  M  E  N  T  -->
                    <Assessments
                        :rec='rec'
                        :assessment='bioPsychShortAssessmentOptions'
                        @normalized='handleNormalAssessment'
                        @optionSelected='handleAssessmentInput'
                    />

                    <div>
                        <div class='bottom-10 dark-text bottom-5 weight-300'><b>Insight</b></div>
                        <div class='flex bottom-20'>
                            <div v-for='(option, key) in bioPsychShortInsightOptions' :key='option'
                                    class='right-30 flex center'>
                                <input class="color" type='radio'
                                        :value='bioPsychShortInsightOptions[key]'
                                        v-model='rec.insight'
                                        :disabled='hasSignature'
                                >
                                <label class="left-0 bottom-0">{{ option }}</label>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class='bottom-10 dark-text bottom-5 weight-300'> <b>Judgement</b> </div>
                        <div class='flex'>
                            <div v-for='(option, key) in bioPsychShortInsightOptions' :key='option'
                                    class='right-30 flex center'>
                                <input class="color" type='radio'
                                        :value='bioPsychShortInsightOptions[key]'
                                        v-model='rec.judgement'
                                        :disabled='hasSignature'
                                >
                                <label class="left-0 bottom-0">{{ option }}</label>
                            </div>
                        </div>
                    </div>

                    <!--  R  I  S  K  S -->
                    <div :class='hideTheRisksForPDF'>
                        <div class='dark-text header-w-bg break-before'>Risks</div>
                        <div class='top-10 bottom-10 do-not-print-me'>
                            <span class='right-10'>Hide this section, contains sensitive data</span>
                            <Toggle
                                id='sensitive-data'
                                class='toggle-switch'
                                :labelClass="'switch'"
                                :spanClass="'slider'"
                                :value='rec.hideRisks'
                                @input='hideRisksFromClient'
                            />
                        </div>
                        <div class='bordered avoid-break'>
                            <div class="flex homi-sui bottom-20">
                                <div class='cidal-label'>
                                    <div class='space-r width-70'>Homicidal</div>
                                    <select
                                        class='homi-sui'
                                        v-model='rec.homicidal_risks'
                                    >
                                        <option v-for='(option, key) in yesOrNo' :key='key' :value='option'>
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <div v-if='hasHomicidalRisks' class='suicidal-details space-l flex-1'>
                                    <label class='details' for='homicidal-risk-details'>Details</label>
                                    <ExpandableTextArea
                                        inputClass="block do-not-print-me"
                                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                        type="text"
                                        placeholder="Details here about why yes was chosen"
                                        id="homicidal-risk-details"
                                        v-model="rec.homicidal_risk_details"
                                        label=""
                                        :limit=500
                                        inputStyle="min-height:20px;"
                                    />
                                </div>
                            </div>
                            <div class="flex homi-sui">
                                <div class='cidal-label'>
                                    <div class='space-r width-70'>Suicidal</div>
                                    <select
                                        class='homi-sui'
                                        v-model='rec.suicidal_risks'
                                    >
                                        <option v-for='(option, key) in yesOrNo' :key='key' :value='option'>
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                                <div v-if='hasSuicidalRisks' class='suicidal-details space-l flex-1'>
                                    <label class='details' for='suicidal-risk-details'>Details</label>
                                    <ExpandableTextArea
                                        inputClass="block do-not-print-me"
                                        oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                        type="text"
                                        placeholder="Details here about why yes was chosen"
                                        id="suicidal-risk-details"
                                        v-model="rec.suicidal_risk_details"
                                        label=""
                                        :limit=500
                                        inputStyle="min-height:20px;"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                </section>

                <section class="tabbed-page form_section_2" id="page-tab-2"  v-disable-all="!pageMeta.permissions.edit">
                    <div class='dark-text header-w-bg avoid-break'>Family History & Childhood</div>
                    <div class="">
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="describe_your_childhood"
                            v-model="rec.describe_your_childhood"
                            label="Describe your childhood and list family members"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="family_abuse_or_trauma"
                            v-model="rec.family_abuse_or_trauma"
                            label="Describe any family abuse or trauma"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="family_history_of_substance_abuse"
                            v-model="rec.family_history_of_substance_abuse"
                            label="Describe any family history with mental health or substance abuse issues"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="childhood_behavioral_issues"
                            v-model="rec.childhood_behavioral_issues"
                            label="Describe any childhood behavioral issues or concerns"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="developmental_delays"
                            v-model="rec.developmental_delays"
                            label="Describe any developmental delays"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                    </div>

                    <div class='dark-text header-w-bg avoid-break'>Current Family and Significant Others</div>
                    <div>
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="current_living_situation"
                            v-model="rec.current_living_situation"
                            label="Describe current living situation (any family members, friends or significant others in the same household)"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="current_family_turmoil"
                            v-model="rec.current_family_turmoil"
                            label="Describe any current family turmoil or issues with significant others"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="family_history_comments"
                            v-model="rec.family_history_comments"
                            label="Please add any additional comments"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                    </div>

                    <div class='dark-text header-w-bg avoid-break'>Medical Conditions</div>
                    <div>
                        <label>Describe any <b>current</b> medical conditions or concerns</label>
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="current_medical_conditions"
                            v-model="rec.current_medical_conditions"
                            label=""
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                        <label>Describe any <b>past</b> medical conditions or concerns</label>
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="past_medical_conditions"
                            v-model="rec.past_medical_conditions"
                            label=""
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="medical_comments"
                            v-model="rec.medical_comments"
                            label="Please add any additional comments"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                    </div>

                    <div class="avoid-break">
                        <div class='dark-text header-w-bg avoid-break'>Mental Health History</div>
                        <div>
                            <ExpandableTextArea
                                class="block"
                                inputClass="block do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="history_of_mental_health_treatment"
                                v-model="rec.history_of_mental_health_treatment"
                                label="Describe any prior counseling/psychiatric therapy/residential treatment"
                                :limit=256
                                inputStyle="min-height:20px;"
                            />
                            <ExpandableTextArea
                                class="block"
                                inputClass="block do-not-print-me"
                                oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                                type="text"
                                id="treatment_effectiveness"
                                v-model="rec.treatment_effectiveness"
                                label="How effective was any prior treatment?"
                                :limit=256
                                inputStyle="min-height:20px;"
                            />
                        </div>
                    </div>

                    <div class='dark-text header-w-bg avoid-break'>Substance Abuse</div>
                    <div>
                        <label>Describe any <b>current</b> substance abuse</label>
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="current_substance_abuse"
                            v-model="rec.current_substance_abuse"
                            label=""
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                        <label>Describe any <b>prior</b> substance abuse</label>
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="prior_substance_abuse"
                            v-model="rec.prior_substance_abuse"
                            label=""
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                    </div>

                    <div class='dark-text header-w-bg avoid-break'>Medication</div>
                    <div>
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="current_medications"
                            v-model="rec.current_medications"
                            label="List any current medications and dosage"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                    </div>
                </section>

                <section class="tabbed-page form_section_3" id="page-tab-3"  v-disable-all="!pageMeta.permissions.edit">
                    <div class='dark-text header-w-bg avoid-break'>Spiritual, Religious and Cultural Influences</div>
                    <div>
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="spiritual_beliefs_and_practices"
                            v-model="rec.spiritual_beliefs_and_practices"
                            label="Describe any important spiritual or religious influences or practices"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="cultural_inﬂuences"
                            v-model="rec.cultural_inﬂuences"
                            label="Describe any important cultural influences or practices"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                    </div>

                    <div class='dark-text header-w-bg avoid-break'>Education</div>
                    <div>
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="highest_level_of_education"
                            v-model="rec.highest_level_of_education"
                            label="What is the highest level of education completed?"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="education_insecurities"
                            v-model="rec.education_insecurities"
                            label="Describe any insecurities related to education"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                    </div>

                    <div class='dark-text header-w-bg avoid-break'>Employment, Occupation, and Hobbies</div>
                    <div>
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="employment_status"
                            v-model="rec.employment_status"
                            label="Describe current level of employment"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="employment_stressors"
                            v-model="rec.employment_stressors"
                            label="Describe any significant stressors related to employment"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="hobbies_or_activities"
                            v-model="rec.hobbies_or_activities"
                            label="Describe any significant hobbies or activities"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                    </div>

                    <div class='dark-text header-w-bg avoid-break'>Legal</div>
                    <div>
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="details_of_any_current_legal_charges"
                            v-model="rec.details_of_any_current_legal_charges"
                            label="Describe any current or prior legal issues"
                            :limit=256
                            inputStyle="min-height:20px;"
                        />
                    </div>

                    <div class="avoid-break">
                        <div class='dark-text header-w-bg avoid-break'>Assessment</div>
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="margin-0 block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="biopsychosocial_assessment_summary"
                            v-model="rec.biopsychosocial_assessment_summary"
                            label=""
                            :limit=4000
                        />
                    </div>
                    <div class="avoid-break">
                        <div class='dark-text header-w-bg avoid-break'>Plans and Recommendations</div>
                        <ExpandableTextArea
                            class="block avoid-break"
                            inputClass="margin-0 block do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="recommendation"
                            v-model="rec.recommendation"
                            label=""
                            :limit=4000
                        />
                    </div>
                    <!--  D  I  A  G  N  O  S  I  S   -->
                    <div>
                        <div class='dark-text header-w-bg avoid-break'>Diagnosis</div>
                        <div class='bordered'>
                            <div class='pn-diagnosis'>
                                <label>Primary Diagnosis</label>
                                <div
                                    v-for='(dx_code, ndx) in rec.diags'
                                    :key='dx_code.generatedId'
                                    class='flex center has-primary-label bottom-10 max800'
                                >
                                    <select
                                        class='w-100'
                                        v-model='rec.diags[ndx].selected'
                                    >
                                        <option v-for='(option, key) in dx_codes'
                                                :key='key'
                                                :value='option'
                                                >
                                            {{ option.service_code }} - {{ option.abbrev }}
                                        </option>
                                    </select>
                                    <i
                                        v-if='rec.diags.length >= 1'
                                        type='button'
                                        class='delete'
                                        @click='removalDiagnosis(ndx)'
                                    >
                                        <span class='material-icons-outlined delete-icon'>delete_outline</span>
                                    </i>
                                </div>
                            </div>

                            <button
                                v-if='rec.diags.length < 1'
                                class='no-bg dark-text add'
                                type='button'
                                @click='addDiagnosis'
                            >
                                <span class='material-icons purple'>add_box</span> Click here to add a diagnosis
                            </button>

                            <button
                                v-if='rec.diags.length >= 1'
                                class='no-bg dark-text add'
                                type='button'
                                @click='addDiagnosis'
                            >
                                <span class='material-icons purple'>add_box</span> Add additional diagnosis
                            </button>
                        </div>
                    </div>

                </section>
                
                <!-- put generated code ABOVE here -->
                
                <section class="flex space-between top-20">
                    <!-- SIGNATURE -->
                    <div class="signing">
                        <FormSignature
                            class="sign-img"
                            :user_id="user_id"
                            :signature_ids="signature_ids"
                            ref="form_signatures"
                            @getSignatures="getSignatures"
                            v-if="signature_ids.length > 0"
                        />
                        <FormSignatureButton
                            class="sign-btn"
                            :rec="rec"
                            :original_rec="original_rec"
                            :signatures_info="signatures_info"
                            :fileName="pageMeta.component_name"
                            :signature_ids="signature_ids"
                            :record_id="record_id"
                            @resetView="reloadData"
                            @fail="fail2sign"
                            @showModal="showPdfModal"
                            :save="updateRecord"
                            :disabled="!pageMeta.permissions.edit"
                            @commenceValidate="makeValidation"
                            :mustValidate="true"
                            :isValid="validYes"
                        />
                    </div>
                    
                    <div class="do-not-print-me">
                        <!-- NEXT / PREVIOUS -->
                        <div class="align-right bottom-15">    
                            <button class="secondary" type="button" @click="goToPreviousSection" v-if="showPrevBtn">
                                <span class="material-icons-outlined">west</span> Previous Section
                            </button>
                            <button class="secondary" type="button" @click="goToNextSection" v-if="showNextBtn">
                                Next Section <span class="material-icons-outlined">east</span>
                            </button>
                        </div>
                        <!-- SAVE AS DRAFT / CANCEL EDIT-->
                        <div class="align-right" v-if="!signature_ids.length">
                            <button
                                v-if="original_signature_ids.length && !signature_ids.length"
                                class="align-right secondary right-15"
                                type="button"
                                @click="signature_ids = original_signature_ids"
                            >Cancel Edit</button>
                            <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" :disabled="!pageMeta.permissions.edit">Save as Draft</ConfirmButton>
                        </div>
                    </div>
                </section>
            </form>
            </ValidationObserver>
            <VIfWorkableModal :hasCloseButton="false" data-html2canvas-ignore="true" v-if="showCreatePdfModal">
                <div>
                    <SignConvertPdfModal />
                </div>
            </VIfWorkableModal>
        </div>
    </div>
</template>

<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import { clients, companies, form_data, offices } from '@/util/apiRequests';
    import FormClient from '@/components/formpieces/FormClient';
    import FormClientFull from '@/components/formpieces/FormClientFull';
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew';
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { ValidationObserver } from 'vee-validate';
    import { dryalex } from '@/mixins/dryalex';

    import { merge } from 'lodash';
    import dayjs from 'dayjs';
    import { generateId } from '@/util/genericUtilityFunctions';
    import Assessments from '@/components/progress-note/Assessments';
    import { bioPsychShortAssessmentOptions, bioPsychShortInsightOptions } from '@/util/assessmentOptions';

    export default {
        name: 'BioPyschoSocialShort',
        props: {
            record_id: {
                type: [String, Number],
                required: false,
                default: 0
            },
            puppet: {
                type: [String],
                required: false,
                default: ''
            }
        },
        components: { Assessments, FormClient, FormClientFull, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, ValidationObserver, SignConvertPdfModal },
        data() {
            return {
                loading: 1,
                updating: 0,
                isEditable: 1,
                user_id: 0,
                client_id: 0,
                page_id: 0,
                rec: {},
                original_rec: {},
                signature_ids: [],
                original_signature_ids: [],
                showCreatePdfModal: false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id: 0,
                router_push: 0,
                counselorOptions: [],
                locationOptions: [],
                showNextBtn: 1,
                showPrevBtn: 0,
                dateEditing: false,
                emergencyContacts: null,
                opts: {
                    highest_level_of_education: [
                        'Some High School',
                        'High School Diploma or GED',
                        'Technical or Occupation Training',
                        'Assosciates Degree',
                        'Some College',
                        "Bachelor's Degree",
                        'Masters Degree',
                        'Doctorate'
                    ],
                    sexual_orientation: [
                        'Asexual',
                        'Bisexual',
                        'Gay',
                        'Lesbian',
                        'Pansexual/Fluid',
                        'Questioning',
                        'Heterosexual'
                    ],
                    cognitive_function: [
                        'Average',
                        'Below Average',
                        'Above Average',
                        'Not Assessed'
                    ],
                    orientation: [
                        "Oriented to person, place and time",
                        "Oriented to person, place; Impaired to time",
                        "Oriented to person, time; Impaired to place",
                        "Oriented to time, place; Impaired to time",
                        "Oriented to person; Impaired to place, time",
                        "Oriented to place; Impaired to person, time",
                        "Oriented to time; Impaired to person, place",
                        "Impaired to person, place, and time",
                        "Not Assessed"
                    ],
                    employment_status: ['Employed', 'Self Employed', 'Unemployed', 'Retired']
                },
                bioPsychShortAssessmentOptions,
                bioPsychShortInsightOptions,
                normalized: false,
                hideRisks: false,
                yesOrNo: ['Yes', 'No', 'Not Assessed'],
                treatmentProgress: {
                    diagnosis_codes: [],
                },
                dx_codes: [],
                rec_assess: {
                    stately: 0,
                    assessor: this.stately,
                },
                list_dropdown: [],
                validYes: false,
            };
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false);
            },
            async reloadData(signature) {
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature);
            },
            showPdfModal(bool) {
                this.showCreatePdfModal = bool;
            },
            getClient(data) {
                this.client = data;
            },
            getCompany(data) {
                this.company = data;
            },
            async getCounselorOptions() {
                try {
                    let result = await this.$api.get(companies.getClinicians(this.$store.state.user.company_id));
                    this.counselorOptions = result.data.map((element) => {
                        return { text: `${element?.first_name} ${element?.last_name}`, value: element.id };
                    });
                    this.counselorOptionsOriginal = this.counselorOptions;
                } catch (err) {
                    this.$toasted.error('Could not retrieve list of counselors.');
                }
            },
            async getOffices() {
                try {
                    const res = await this.$api.post(offices.getOffices(), { criteria: {} });
                    this.locationOptions = res.data.rows;
                } catch (err) {
                    console.log(err);
                    //this.$toasted.error('Could not retrieve list of offices');
                }
            },

            //signatures that come from the FormSignatures component
            getSignatures(data) {
                this.signatures_info = data ? data : [];
            },
            async makeValidation() {
                this.validYes = false;
                const reviewed = this.reviewAssessments();
                const form = this.$refs.form_bio;
                const isValid = await form.validate();

                if (!isValid || !reviewed) {
                    this.$toasted.error("Mental Status Assessment section is required.");
                    return;
                }
                this.validYes = true;
            },
            async updateRecord(e) {
                const reviewed = this.reviewAssessments();
                const form = this.$refs.form_bio;
                const isValid = await form.validate();

                if (!isValid || !reviewed) {
                    this.$toasted.error("Mental Status Assessment section is required.");
                    return;
                }

                if (!this.updating) {
                    this.updating = 1;
                    try {
                        e.preventDefault();
                    } catch (error) {
                        /**/
                    }

                    let xthis = await dryalex.form_data_update_record(this, e);
                    merge(this, xthis);
                    if (this.router_push) {
                        this.router_push = 1;
                        this.$forceUpdate();
                    }
                    this.original_signature_ids = this.signature_ids;
                }
            },
            async init() {
                await Promise.all([
                    this.getCounselorOptions(),
                    this.getOffices(),
                    this.get_list_dropdown(),
                    this.getTreatmentPlan(this.client_id),
                    this.get_dx_codes(),
                ]);
                let xthis = await dryalex.form_data_init(this);
                merge(this, xthis);
                if (!this.rec.date) this.rec.date = new Date();
                if (!this.assessor) this.assessor = this.$store.state.user.id;
                if (!this.rec.location) this.rec.location = this.$store.state.user.office_id;
                if (!this.rec.homicidal_risks) this.rec.homicidal_risks = "Not Assessed";
                if (!this.rec.suicidal_risks) this.rec.suicidal_risks = "Not Assessed";
                if (this.rec.diags.length < 1) this.rec.diags = this.treatmentProgress.diagnosis_codes;
                for (let op of this.bioPsychShortAssessmentOptions) {
                    for (let cat of op.categories) {
                        cat.req = false;
                    }
                }
                this.loading = 0;
            },

            // formatNewAssessmentGoodies(){
            //
            // }

            toggleDateEditing() {
                this.dateEditing = !this.dateEditing;
            },

            async changeTab(e) {
                const tabNumber = parseInt(e.target.id.match(/\d+/)[0], 10);

                document.querySelector('input[name=page-tab-input][checked="checked"]').setAttribute('checked', false);

                document.querySelector(`#${e.target.id}`).setAttribute('checked', 'checked');

                this.showNextBtn = 1;
                this.showPrevBtn = 1;

                const all = document.querySelectorAll('input[name=page-tab-input]');
                const numberOfTabs = all.length;

                if (tabNumber === 1) {
                    this.showPrevBtn = 0;
                } else if (tabNumber === numberOfTabs) {
                    this.showNextBtn = 0;
                }
            },
            async show_all_tabs4pdf() {
                document.querySelectorAll('.do-not-print-me').forEach((e) => (e.style.display = 'none'));
                document
                    .querySelectorAll('div.rec-data-div form section.tabbed-page')
                    .forEach((e) => (e.style.display = 'block'));
                document.querySelectorAll('input[name=page-tab-input]').forEach((e) => e.setAttribute('checked', true));
            },

            goToNextSection() {
                this.showPrevBtn = 1;
                const forRange = document.querySelectorAll('input[name=page-tab-input]');
                const max = forRange.length;
                const selected = document.querySelector('input[name=page-tab-input][checked="checked"]');
                const selectedTab = parseInt(selected.id.match(/\d+/)[0]);
                if (selectedTab < max) {
                    const nextTab = document.querySelector(
                        `input[name=page-tab-input][id=page-tab-${selectedTab + 1}]`
                    );
                    nextTab.click();
                }
                if (selectedTab + 1 === max) {
                    this.showNextBtn = 0;
                }
            },
            async goToPreviousSection() {
                this.showNextBtn = 1;
                const selected = document.querySelector('input[name=page-tab-input][checked = "checked"]');
                const selectedTab = parseInt(selected.id.match(/\d+/)[0], 10);
                if (selectedTab !== 1) {
                    const previousTab = document.querySelector(
                        `input[name=page-tab-input][id=page-tab-${selectedTab - 1}]`
                    );
                    previousTab.click();
                }
                if (selectedTab - 1 === 0) {
                    this.showPrevBtn = 0;
                }
            },

            handleAssessmentInput(input) {

                this.$set(this.rec.assessments[input.category], input.index, input.value)

            },
            handleNormalAssessment(isNormalized) {
                if (isNormalized) {
                    this.normalizeAssessment();
                    return;
                }

                //if isNormalized == false
                this.undoNormalizeAssessment();
            },
            normalizeAssessment() {
                //behavioral
                this.$set(this.rec.assessments.behavior, 1, 'Attentive');
                this.$set(this.rec.assessments.behavior, 2, 'Cooperative');
                //appearance
                this.$set(this.rec.assessments.appearance, 0, 'Well Groomed');
                this.$set(this.rec.assessments.appearance, 1, 'Appropriate Dress');
                //mood
                this.$set(this.rec.assessments.mood, 1, 'Pleasant');           //sets mood
                this.$set(this.rec.assessments.mood, 2, 'Calm');
                //affect
                this.$set(this.rec.assessments.affect, 1, 'Appropriate');
                //speech
                this.$set(this.rec.assessments.speech, 1, 'Normal');
                //motor activity
                this.$set(this.rec.assessments.motor, 1, 'Normal');
                //eye contact
                this.$set(this.rec.assessments.eye, 1, 'Normal');
                //attention
                this.$set(this.rec.assessments.attention, 1, 'Normal');
                //delusions
                this.$set(this.rec.assessments.delusions, 1, 'None');
                //hallucinations
                this.$set(this.rec.assessments.hallucinations, 1, 'None');
                //other perceptions
                this.$set(this.rec.assessments.other, 1, 'None');
                //oriented to
                this.$set(this.rec.assessments.oriented, 1, 'Person');
                this.$set(this.rec.assessments.oriented, 2, 'Place');
                this.$set(this.rec.assessments.oriented, 3, 'Time');
                this.$set(this.rec.assessments.oriented, 4, 'Situation');
                this.$set(this.rec.assessments.oriented, 5, 'Object');
                //thoughts
                this.$set(this.rec.assessments.thought, 1, 'Normal');
                //memory
                this.$set(this.rec.assessments.memory, 1, 'None');


                this.rec.insight = "Good"
                this.rec.judgement = "Good"

                return;
            },

            undoNormalizeAssessment(){
                //behavioral
                this.$set(this.rec.assessments.behavior, 1, '');
                this.$set(this.rec.assessments.behavior, 2, '');
                //appearance
                this.$set(this.rec.assessments.appearance, 0, '');
                this.$set(this.rec.assessments.appearance, 1, '');
                //mood
                this.$set(this.rec.assessments.mood, 1, '');           //sets mood
                this.$set(this.rec.assessments.mood, 2, '');
                //affect
                this.$set(this.rec.assessments.affect, 1, '');
                //speech
                this.$set(this.rec.assessments.speech, 1, '');
                //motor activity
                this.$set(this.rec.assessments.motor, 1, '');
                //eye contact
                this.$set(this.rec.assessments.eye, 1, '');
                //attention
                this.$set(this.rec.assessments.attention, 1, '');
                //delusions
                this.$set(this.rec.assessments.delusions, 1, '');
                //hallucinations
                this.$set(this.rec.assessments.hallucinations, 1, '');
                //other perceptions
                this.$set(this.rec.assessments.other, 1, '');
                //oriented to
                this.$set(this.rec.assessments.oriented, 1, '');
                this.$set(this.rec.assessments.oriented, 2, '');
                this.$set(this.rec.assessments.oriented, 3, '');
                this.$set(this.rec.assessments.oriented, 4, '');
                this.$set(this.rec.assessments.oriented, 5, '');
                //thoughts
                this.$set(this.rec.assessments.thought, 1, '');
                //memory
                this.$set(this.rec.assessments.memory, 1, '');

                this.rec.insight = ""
                this.rec.judgement = ""
            },

            normalLimits(e) {
                //if toggle is checked
                if (e == true) {
                    this.normalized = true;
                }
                //if toggle is not checked
                else {
                    this.normalized = false;
                }
            },
            hideRisksFromClient(e) {
                switch (e) {
                    case true:
                        this.hideRisks = true;
                        this.rec.hideRisks = this.hideRisks;
                        break;
                    case false:
                        this.hideRisks = false;
                        this.rec.hideRisks = this.hideRisks;
                        break;
                }
            },
            async getTreatmentPlan(client) {
                try {
                    let res = await this.$api.get(form_data.getTreatmentPlan(client));

                    /** If the client has a treatment plan on file **/
                    if (res.data[0]) {
                        let txPlan = res.data[0];
                        this.treatmentProgress = JSON.parse(txPlan.raw_data);
                        this.treatmentProgress.diagnosis_codes = this.treatmentProgress.diagnosis_codes.map(
                            (dxCode) => {
                                dxCode.generatedId = generateId();
                                dxCode.primary = false;
                                return dxCode;
                            }
                        );
                        for (let dc in this.treatmentProgress.diagnosis_codes) {
                            if (dc == 0) {
                                this.treatmentProgress.diagnosis_codes[dc].primary = true;
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            async get_dx_codes() {
                const res = await this.$api.get(`/services/dx-codes`);
                let options = res.data?.map((element) => {
                    return { service_code: element.service_code, abbrev: element.abbrev, id: element.id };
                });
                this.dx_codes = options;
                this.dx_codes = res.data.map((element) => {
                    element.selected = {
                        service_code: element.service_code,
                        abbrev: element.abbrev,
                        id: element.id
                    };
                    return element;
                });
            },
            addDiagnosis() {
                if (this.rec.diags.length < 1) {
                    this.rec.diags.push({ generatedId: generateId(), primary: true });
                    return;
                }
                this.rec.diags.push({ generatedId: generateId(), primary: false });
                return;
            },

            removalDiagnosis(index) {
                if (index == 0) {
                    this.rec.diags.shift();
                    return;
                }

                this.rec.diags = this.rec.diags.filter(
                    (diag) => diag !== this.rec.diags[index]
                );
                return;
            },
            async get_list_dropdown() {
                const body = {
                    criteria: { page: { num_per_page: 100, page_num: 1 } },
                };
                const res = await this.$api.post('/referrals', body);
                this.list_dropdown = res.data.rows;
            },
            reviewAssessments() {
                let reqs = [];
                for (let op in this.rec.assessments) {
                    if (Array.isArray(this.rec.assessments[op])) {
                        if (this.rec.assessments[op].every(element => (element === null || element === ""))) {
                            reqs.push(this.setAssessmentRequired(op, true));
                        } else {
                            reqs.push(this.setAssessmentRequired(op, false));
                        }
                    }
                }
                return reqs.every(arr => arr === false);
            },
            setAssessmentRequired(op, req) {
                for (let op2 of this.bioPsychShortAssessmentOptions) {
                    let cat = op2.categories.find(x => x.name.toLowerCase() === op.toLowerCase());
                    if (cat !== undefined) {
                        cat.req = req;
                    }
                }
                return req;
            },
            printView() {
                window.print();
            },
        },
        computed: {
            dateDate: {
                get() {
                    return dayjs(this.rec.date).format('YYYY-MM-DD');
                },
                set(value) {
                    const newDate = dayjs(value);
                    const oldDate = dayjs(this.rec.date);
                    this.rec.date = newDate
                        .set('hour', oldDate.hour())
                        .set('minute', oldDate.minute())
                        .toDate();
                }
            },
            dateTime: {
                get() {
                    return dayjs(this.rec.date).format('HH:mm');
                },
                set(value) {
                    const newDate = dayjs(this.rec.date);
                    this.rec.date = newDate
                        .set('hour', value.split(':')[0])
                        .set('minute', value.split(':')[1])
                        .toDate();
                }
            },
            formattedDate() {
                return dayjs(this.rec.date).format('YYYY-MM-DD hh:mm A');
            },
            hideTheRisksForPDF() {
                if (this.rec.hideRisks == true) {
                    return 'do-not-print-me';
                }
                return '';
            },
            hasSuicidalRisks: function() {
                if (this.rec.suicidal_risks == 'Yes') {
                    return true;
                } else return false;
            },
            hasHomicidalRisks: function() {
                if (this.rec.homicidal_risks == 'Yes') {
                    return true;
                } else return false;
            },
            assessor: {
                get() {
                    return this.rec_assess.assessor;
                },
                set(counsellor) {
                    this.rec_assess.assessor = counsellor;
                },
            },
            office_loc_disabled: function() {
                if (this.locationOptions.length > 1) {
                    return false;
                }
                return true;
            }
        },
        async created() {
            await this.init();
            if (this.puppet == 'pdf') {
                await this.show_all_tabs4pdf();
            }
        },
        watch: {
            showCreatePdfModal(newVal) {
                if (window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if (newVal == false) {
                    this.closeModalOverlay();
                }
            },
            normalized() {
                if (this.normalized == true) {
                    this.$set(this.rec.assessments.behavior, 1, 'Attentive'); //sets behavior
                    this.$set(this.rec.assessments.behavior, 2, 'Cooperative'); //sets behavior
                    this.$set(this.rec.assessments.appearance, 0, 'Well Groomed'); //sets appearance
                    this.$set(this.rec.assessments.appearance, 1, 'Appropriate Dress');
                    this.$set(this.rec.assessments.mood, 1, 'Pleasant'); //sets mood
                    this.$set(this.rec.assessments.mood, 2, 'Calm');
                    this.$set(this.rec.assessments.affect, 1, 'Appropriate'); //sets affect
                    this.$set(this.rec.assessments.speech, 1, 'Normal'); //sets speech
                    this.$set(this.rec.assessments.thought, 1, 'Normal'); //sets thought proccess
                } else {
                    //resets normalized limits
                    this.$set(this.rec.assessments.behavior, 1, '');
                    this.$set(this.rec.assessments.behavior, 2, '');
                    this.$set(this.rec.assessments.appearance, 0, '');
                    this.$set(this.rec.assessments.appearance, 1, '');
                    this.$set(this.rec.assessments.mood, 1, '');
                    this.$set(this.rec.assessments.mood, 2, '');
                    this.$set(this.rec.assessments.affect, 1, '');
                    this.$set(this.rec.assessments.speech, 1, '');
                    this.$set(this.rec.assessments.speech, 4, '');
                    this.$set(this.rec.assessments.thought, 1, '');
                }
            },
        }
    };
</script>
